import { render, staticRenderFns } from "./listadoHistorial.vue?vue&type=template&id=1b90b506&scoped=true&"
import script from "./listadoHistorial.vue?vue&type=script&lang=js&"
export * from "./listadoHistorial.vue?vue&type=script&lang=js&"
import style0 from "./listadoHistorial.vue?vue&type=style&index=0&id=1b90b506&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1b90b506",
  null
  
)

export default component.exports